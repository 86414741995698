import React from "react";
import Button from "@plasma/ui.general.button";
import useAssetIdentificationHook from "./assetIdentification.hook";
import {
  AssetIdentificationContainer,
  ButtonContainer,
} from "./assetIdentification.styles";
import { useTranslation } from "react-i18next";
import IdentifierOverview from "../../../../shared/IdentifierOverview/IdentifierOverview";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../stores/store";

interface Props {
  chosenAssetId: number;
}

function AssetIdentificationMetadata(props: Props) {
  const { EditModeStore } = useStore();
  const logic = useAssetIdentificationHook(props.chosenAssetId);
  const { t } = useTranslation();
  return (
    <AssetIdentificationContainer>
      <IdentifierOverview
        onChange={logic.changeHandler}
        value={logic.values}
      />
      {
        EditModeStore.editMode && (
          <ButtonContainer>
            <>
              <Button.Save
                disabled={!(logic.objectChanged && logic.objectValid)}
                loading={logic.isUpdating}
                title={t("button.save")}
                onClick={logic.updateIdentification}
              />
              <Button.Cancel
                disabled={!logic.objectChanged}
                title={t("button.cancel")}
                type="secondary"
                onClick={logic.onCancel}
              />
            </>
          </ButtonContainer>
        )
      }
    </AssetIdentificationContainer>
  );
}

export default observer(AssetIdentificationMetadata);
