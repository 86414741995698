import { createContext, useContext } from 'react';
import AssetStore from './AssetStore';
import ExternalIdDefinitionStore from './ExternalIdDefinitionStore';
import EditModeStore from './EditModeStore';

interface Store {
  AssetStore: AssetStore;
  ExternalIdDefinitionStore: ExternalIdDefinitionStore;
  EditModeStore: EditModeStore;
}

const store: Store = {
  AssetStore: new AssetStore(),
  ExternalIdDefinitionStore: new ExternalIdDefinitionStore(),
  EditModeStore: new EditModeStore(),
};

const StoreContext = createContext(store);

export const useStore = () => useContext(StoreContext);
