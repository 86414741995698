import React from "react";

import Form from '@plasma/ui.input.form'
import FormField from '@plasma/ui.input.form-field';
import TextInput from '@plasma/ui.input.text-input';
import TextArea from '@plasma/ui.input.text-area';
import Button from '@plasma/ui.general.button';
import { observer } from "mobx-react-lite";

import { AssetCompleteDto } from "../../../../../models/dtos/AssetDtos";
import { AssetGeneralContainer, ButtonContainer } from "./assetGeneral.styles";
import useAssetGeneralHook from "./assetGeneral.hook";
import { checkObjectsAreEqual } from "../../../../../utils/objectsEqualityChecker";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../../stores/store";

interface Props {
    chosenAssetId: number
}

function AssetGeneralMetadata(props: Props) {
    const { EditModeStore } = useStore();
    const logic = useAssetGeneralHook(props.chosenAssetId);
    const {t} = useTranslation()
    
    return (
        <AssetGeneralContainer>
            <Form
                initialValues={logic.initialAsset}
                key={logic.formKey}
                onChange={ (data: Partial<AssetCompleteDto>) => { logic.handleSetUpdatedAsset(data);
            } }
                >
                <FormField required label={t("asset-overview.tab.general.label.name")} name="name" hint={t("asset-overview.tab.general.hint.name")}>
                    <TextInput readOnly={!EditModeStore.editMode} required/>
                </FormField>
                <FormField label={t("asset-overview.tab.general.label.description")} name="description" hint={t("asset-overview.tab.general.hint.description")}>
                    <TextArea readOnly={!EditModeStore.editMode} rows={5}/>
                </FormField>
                <FormField required label={t("asset-overview.tab.general.label.code")} name="code" hint={t("asset-overview.tab.general.hint.code")}>
                    <TextInput readOnly={!EditModeStore.editMode} required/>
                </FormField>
            </Form>
            {
                EditModeStore.editMode && (
                    <ButtonContainer>
                        {
                            checkObjectsAreEqual(logic.initialAsset ?? {}, logic.updatedAsset ?? {}) ? (
                                <>
                                    <Button.Save title={t("button.save")} disabled/>
                                    <Button.Cancel title={t("button.cancel")} disabled/>
                                </>
                            ) : (
                                <>
                                    <Button.Save title={t("button.save")} onClick={() => {logic.onUpdateAsset(logic.updatedAsset!)}}/>
                                    <Button.Cancel title={t("button.cancel")} type="secondary" onClick={() => {logic.onCancelChanges()}}/>
                                </>
                            )
                        }
                    </ButtonContainer>
                )
            }

        </AssetGeneralContainer>
    )
}

export default observer(AssetGeneralMetadata);