import React from "react";
import Button from "@plasma/ui.general.button";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import {
  AssetRelationContainer,
  ButtonContainer,
  ClearIcon,
  NotEditableTextInput,
} from "./assetRelation.styles";
import useAssetRelationHook from "./assetRelation.hook";
import { useTranslation } from "react-i18next";
import Select from "@plasma/ui.input.select";
import Tooltip from "@plasma/ui.display.tooltip";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../stores/store";

interface Props {
  chosenAssetId: number;
}

function AssetRelationMetadata(props: Props) {
  const { EditModeStore } = useStore();
  const logic = useAssetRelationHook(
    props.chosenAssetId
  );
  const { t } = useTranslation();

  return (
    <AssetRelationContainer>
        <Form onChange={logic.handleOnChange} initialValues={logic.selectedOption}>
          <FormField
            required
            label={t("asset-overview.tab.relation.label.parent")}
            name="parent"
            hint={t("asset-overview.tab.relation.hint.parent")}
          >
            {EditModeStore.editMode && (!logic.initialParent || logic.parentCleared) ? (
              <Select
                className="parent-select"
                showSearch
                filterOption={(input, option) =>
                  (
                    (option?.label as string)?.toLocaleLowerCase() ?? ""
                  ).includes(input.toLowerCase())
                }
                allowClear
                options={logic.sufficientParentSelectOptions}
              ></Select>
            ) : (
              <>
                <NotEditableTextInput value={logic.initialParent} readOnly />
                {(EditModeStore.editMode && logic.initialParent) && (
                  <Tooltip title={t("asset-overview.tab.relation.hint.delete-icon")}>
                    <ClearIcon
                      name="delete"
                      variant="filled"
                      onClick={() => {
                        logic.onClearParent();
                      }}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </FormField>
        </Form>
      {
        EditModeStore.editMode && (
          <ButtonContainer>
            {!logic.objectChanged ? (
              <>
                <Button.Save title={t("button.save")} disabled />
                <Button.Cancel title={t("button.cancel")} disabled />
              </>
            ) : (
              <>
                <Button.Save
                  title={t("button.save")}
                  onClick={() => {
                    logic.onUpdateParent(logic.parentToUpdate!);
                  }}
                />
                <Button.Cancel
                  title={t("button.cancel")}
                  onClick={() => {
                    logic.onCancelChanges();
                  }}
                />
              </>
            )}
          </ButtonContainer>
        )
      }
    </AssetRelationContainer>
  );
}

export default observer(AssetRelationMetadata);
