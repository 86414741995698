import { makeAutoObservable } from "mobx";

export default class EditModeStore {
  editMode: boolean = false;

  setEditMode = (editMode: boolean) => {
    this.editMode = editMode;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
