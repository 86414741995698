import React, { Dispatch } from "react";
import Button from "@plasma/ui.general.button";
import Modal from "@plasma/ui.layout.modal";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import useCreateAssetModalHook from "./createAssetModal.hook";
import { AssetToCreate } from "../../../../../models/types/AssetToCreate";
import { useTranslation } from "react-i18next";
import Select from "@plasma/ui.input.select";
import IdentifierOverview from "../../../../shared/IdentifierOverview/IdentifierOverview";
import * as styled from "./createAssetModal.style";
import { useStore } from "../../../../../stores/store";

interface Props {
  createAssetModalOpened: boolean;
  setCreateAssetModalOpened: Dispatch<boolean>;
}

function CreateAssetModal(props: Props) {
  const { t } = useTranslation();
  const logic = useCreateAssetModalHook();
  const { AssetStore } = useStore();

  return (
    <Modal
      footer={false}
      onCancel={() => props.setCreateAssetModalOpened(false)}
      title={t("create-asset.header")}
      centered={true}
      destroyOnClose={true}
      visible={props.createAssetModalOpened}
    >
      <styled.CreatAssetModal>
        <Form  
          onChange={(data: Partial<AssetToCreate>) => {
            logic.setAssetToCreate(data as AssetToCreate);
          }}
        >
          <FormField
            required
            label={t("create-asset.label.name")}
            name="name"
            hint={t("create-asset.hint.name")}
          >
            <TextInput required />
          </FormField>
          <FormField
            label={t("create-asset.label.description")}
            name="description"
            hint={t("create-asset.hint.description")}
          >
            <TextArea rows={5} />
          </FormField>
          <FormField
            required
            label={t("create-asset.label.code")}
            name="code"
            hint={t("create-asset.hint.code")}
          >
            <TextInput />
          </FormField>
          <FormField
            label={t("create-asset.label.parent-equipment")}
            name="parent"
            hint={t("create-asset.hint.parent-equipment")}
          >
            <Select
              filterOption={(input, option) =>
                ((option?.label as string)?.toLocaleLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              allowClear
              showSearch
              options={AssetStore.parentSelectOptions}
              className="field"
              showArrow
            />
          </FormField>
          <FormField name={"externalIds"}>
            <IdentifierOverview/>
          </FormField>
        </Form>
        <div className="btn-container">
          <Button
            title="button.cancel"
            type="secondary"
            onClick={() => props.setCreateAssetModalOpened(false)}
          />
          <Button
            disabled={!logic.objectValid}
            title="button.save"
            type="primary"
            onClick={() => {
              logic.onCreateAsset(logic.assetToCreate!);
              props.setCreateAssetModalOpened(false);
            }}
          />
        </div>
      </styled.CreatAssetModal>
    </Modal>
  );
}

export default CreateAssetModal;
